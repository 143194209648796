import HTTP from "../http-common";

/**
 * Получение данных об исполнителе по id арендодателя
 *
 * @module getLegalInfo
 *
 *
 * @return {Object} Promise
 */
export default (userId) =>
  HTTP.get("json/users/getLegalInfo", {
    params: {
      userId
    },
    headers: {
      "api-version": "2.0"
    },
    cache: false,
  });
