<i18n>
{
  "ru":{
    "placeholder": "Курорт, город или адрес"
  },
  "en":{
    "placeholder": "Resort, city or address"
  }
}
</i18n>
<template>
  <div class="choise-location">
    <div class="choise-location__field">
      <input
        ref="input"
        class="choise-location__input"
        autocomplete="off"
        type="text"
        name="choise-location"
        v-model="locationFieldValue"
        :placeholder="t('placeholder')"
      />
      <button
        class="choise-location__close"
        @click="onClear"
        v-if="locationFieldValue.length"
      ></button>
    </div>
    <ListMobile
      class="choise-location__list"
      :variantsQuery="variantsQuery"
      :locationFieldValue="locationFieldValue"
      @applyVariant="applyVariant"
    />
  </div>
</template>
<script>
import ListMobile from "./ListMobile";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  name: "BaseSuggestionsSearchModal",
  components: {
    ListMobile,
  },
  data() {
    return {
      locationFieldValue: this.modelValue?.title || this.locationText,
      focused: false,
      variantsQuery: [],
    };
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    modelValue: Object,
    locationText: String,
    showOnlyOptions: Boolean,
  },
  emits: ["update:modelValue", "finishChoiseLocation"],
  computed: {
    ...mapState("search", ["term", "listSuggestions"]),
  },
  watch: {
    locationFieldValue: _.debounce(async function (value) {
      const variants = await this.getTermSuggestionsWithBoundingBox(value);
      this.variantsQuery = variants.slice(0, 5);
    }, 900),
  },
  methods: {
    ...mapActions("search", [
      "setGeocoder",
      "getTermSuggestionsWithBoundingBox",
      "saveVariantInStorage",
    ]),
    onChoice(variant) {
      this.locationFieldValue = variant.title;
      this.$nextTick(() => {
        this.$refs.input?.blur();
        this.focused = false;
      });
    },
    onClear() {
      this.locationFieldValue = "";
      this.$refs.input.focus();
    },
    applyVariant(variant, type) {
      this.onChoice(variant);
      this.$emit("update:modelValue", variant, type);
      this.$emit("finishChoiseLocation", variant, type);
    },
  },
  mounted() {
    if (this.showOnlyOptions) {
      setTimeout(() => {
        this.$refs.input.focus();
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>
.choise-location {
  width: 100%;
  &__field {
    margin-top: 16px;
    padding: 0 16px;
    position: relative;
  }
  &__input {
    padding: 16px;
    padding-right: 42px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #1c1c1c;
    border-radius: 10px;
    border: 1px solid #e7e9f3;
    &:focus {
      outline: none;
    }
  }
  &__close {
    padding: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    border-radius: 50%;
    border: none;
    background-color: #e7e9f3;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: url("~/assets/img/icon/close.svg") no-repeat center;
      background-size: cover;
    }
  }
  &__list {
    max-height: initial;
    flex-grow: 1;
    position: static;
    box-shadow: none;
    margin-top: 5px;
    width: 100%;
    &.suggestions-list--wrapper {
      font-size: 14px;
    }
    :deep() {
      .suggestions__list {
        &-elem--title {
          text-overflow: none;
          text-overflow: initial;
          overflow: initial;
        }
        &-title {
          padding-bottom: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
