export default (vueApp) => {
  vueApp.directive("sticky-sidebar", {
    updated: function (el) {
      el.__vueHeightElem__ = el.getBoundingClientRect().height;
    },
    mounted: function (el) {
      const OPTIONS = {
        topSpacing: 80,
        bottomSpacing: -30,
      };
      el.__vueHeightElem__ = el.getBoundingClientRect().height;
      el.style.position = "sticky";
      el.style.willChange = "top";

      let lastScroll = 0;
      let OFFSET = -90000;

      const handleScroll = () => {
        let currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
        OFFSET += lastScroll - currentScroll;
        let BOTTOMOFFSET =
          window.innerHeight - el.__vueHeightElem__ - OPTIONS.bottomSpacing;
        if (OFFSET > OPTIONS.topSpacing) {
          OFFSET = OPTIONS.topSpacing;
        } else if (OFFSET < BOTTOMOFFSET) {
          OFFSET = BOTTOMOFFSET;
        }
        if (Math.ceil(OFFSET) !== parseInt(el.style.top)) {
          el.style.top = Math.ceil(OFFSET) + "px";
        }
        lastScroll = currentScroll;
      };

      /* Feature detection */
      let passiveSupported = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: function () {
              passiveSupported = true;
            },
          })
        );
      } catch (err) {}
      document.addEventListener(
        "scroll",
        handleScroll,
        passiveSupported ? { passive: true } : false
      );
      el.$destroy = () => document.removeEventListener("scroll", handleScroll);
    },
  });
  vueApp.directive("sticky-sidebar-button", {
    mounted: function (el) {
      const offsetCheck = 75 + 65;
      const handleScroll = () => {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        const height = window.innerHeight - Math.max(offsetCheck - top, 0);
        if (parseInt(el.style.height) !== height) {
          el.style.height = height + "px";
        }
      };

      /* Feature detection */
      let passiveSupported = false;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: function () {
              passiveSupported = true;
            },
          })
        );
      } catch (err) {}
      document.addEventListener(
        "scroll",
        handleScroll,
        passiveSupported ? { passive: true } : false
      );
      el.$destroy = () => document.removeEventListener("scroll", handleScroll);
    },
  });
  vueApp.directive("scroll",{
    beforeMount: function (el, binding) {
      el.___handler___ = function (evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener("scroll", el.___handler___);
        }
      };
      window.addEventListener("scroll", el.___handler___);
    },
    unmounted: function (el) {
      window.removeEventListener("scroll", el.___handler___);
    },
  });
  vueApp.directive("focus", {
    mounted(el) {
      el.focus();
    },
  });
};
