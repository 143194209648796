<i18n lang="json">
{
	"ru": {
		"titleHistory": "История поиска",
		"titlePopular": "Популярные направления",
    "titleHotels": "Гостиницы"
	},
	"en": {
    "titleHistory": "Search history",
    "titlePopular": "Popular destinations",
    "titleHotels": "Hotels"
	}
}
</i18n>
<template>
  <div
    ref="suggestionsList"
    class="suggestions-list--wrapper tmp-font--average"
  >
    <div class="suggestions-list__scroll">
      <template v-if="variantsQueryList">
        <div class="suggestions-list">
          <div
            v-for="(variant, index) in variantsQueryList.directions"
            :key="index"
            :data-variant-id="index"
            class="suggestions-list-elem"
            :class="{ 'suggestions-list-elem__active': index === selectedIndex }"
            @mouseenter="selectedIndex = index"
            @click="handleVariantApply(variant, null, 'directions')"
          >
            <span
              class="suggestions-list-elem--icon suggestions-list-elem--mark"
            ></span>
            <span class="suggestions-list-elem--title"
              >{{ variant.title }}{{ variant.title_ext ? "," : "" }}</span
            >
            <span class="suggestions-list-elem--location">{{
              variant.title_ext
            }}</span>
            <span class="suggestions-list-elem--count">{{
              format(variant.count)
            }}</span>
          </div>

          <div
            v-if="variantsQueryList.hotels.length"
            class="suggestions-list-title tmp-font--medium"
          >
            {{ t("titleHotels") }}
          </div>
          <div
            v-for="(variant, index) in variantsQueryList.hotels"
            :key="index + variantsQueryList.directions.length"
            :data-variant-id="index + variantsQueryList.directions.length"
            class="suggestions-list-elem"
            :class="{
              'suggestions-list-elem__active':
                index + variantsQueryList.directions.length === selectedIndex,
            }"
            @mouseenter="
              selectedIndex = index + variantsQueryList.directions.length
            "
            @click="handleVariantApply(variant, 'hotels')"
          >
            <span
              class="suggestions-list-elem--icon suggestions-list-elem--mark"
            ></span>
            <span class="suggestions-list-elem--title"
              >{{ variant.title }}{{ variant.title_ext ? "," : "" }}</span
            >
            <span class="suggestions-list-elem--location">{{
              variant.title_ext
            }}</span>
            <span class="suggestions-list-elem--count">{{
              format(variant.count)
            }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="variantsHistoryList.length">
          <div class="suggestions-list-title tmp-font--medium">
            {{ t("titleHistory") }}
          </div>
          <div class="suggestions-list">
            <HistoryListElement
              v-for="(variant, index) in variantsHistoryList"
              :key="index"
              :index="index"
              :selected-index="selectedIndex"
              :variant="variant"
              @mouseenter="selectedIndex = index"
              @applyVariants="handleVariantApply($event, null, 'history')"
            >
      
            </HistoryListElement>
          </div>
        </template>
        <div class="suggestions-list-title tmp-font--medium">
          {{ t("titlePopular") }}
        </div>
        <div class="suggestions-list popular">
          <div
            v-for="(variant, index) in variantsPopular"
            :key="index + variantsHistory.length"
            :data-variant-id="index + variantsHistory.length"
            class="suggestions-list-elem"
            data-enter="enter"
            :class="{
              'suggestions-list-elem__active':
                index + variantsHistory.length === selectedIndex,
            }"
            @mouseenter="selectedIndex = index + variantsHistory.length"
            @click="handleVariantApply(variant, null, 'popular')"
          >
            <span
              class="suggestions-list-elem--icon suggestions-list-elem--mark"
            ></span>
            <span class="suggestions-list-elem--title"
              >{{ variant.title }}{{ variant.title_ext ? "," : "" }}</span
            >
            <span class="suggestions-list-elem--location">{{
              variant.title_ext
            }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { Search } from "@/components/api/";
import variantsPopular from "./popular.json";
import HistoryListElement from "./HistoryListElement"
import _ from "lodash";
// import levenshtein from "js-levenshtein";
import { useI18n } from 'vue-i18n';


export default {
  name: "BaseSuggestionsList",
  components:{
    HistoryListElement
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    withoutLast: {
      type: Boolean,
      default: false
    },
    activeSearchElement: {
      type: Boolean,
      default: false
    },
    longList: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  mounted() {
    // let arr = ["Крым", "Санкт-Петербург", "Ялта", "Москва", "Сочи", "Минск", "Евпатория", "Севастополь", "Казань", "Феодосия"];
    // // Search.getTermSuggestions(value);
    // let promises = arr.map(q=> Search.getTermSuggestionsWithBoundingBox(q))
    // let a;

    this.loadHistory();
    setTimeout(() => {
      window.addEventListener("keyup", this.onKey);
    }, 1000);
    // this.maxHeightWrap();
  },
  beforeUnmount: function () {
    // Если есть ответ с API
    // Если ранее небыло выбора
    // Если включен авто выбор при смене фокуса
    // ТО, выбираем
    if (this.variantsQuery.length > 0 && !this.isChoice && this.isComplete) {
      this.applyVariants(this.variants[0]);
    }
    window.removeEventListener("keyup", this.onKey);
  },
  methods: {
    ...mapActions("search", [
      "setGeocoder",
      "getTermSuggestionsWithBoundingBox",
    ]),
    ...mapActions("filter", ["updateFilters"]),
    ...mapMutations("search", [
      "setSelectedVariant",
      "setCondidateForHistory",
      "setVariantsHistory",
      "setGuests",
      "setDateFromHistorylist",
      "setAnaliticParamForSearchWidList",
      "setPlace",
      "setTerm",
      "setBound",
      "setMap",
      "setListType",
    ]),
    // compareTypeOfVariants(variant) {
    //   if(!variant.data) {
    //     return 'suggestions-list-elem--mark'
    //   }
    //   if(variant.data && variant.data.type === 'object') {
    //     return 'suggestions-list-elem--room'
    //   }
    //   if(variant.data && variant.data.type === 'hotel') {
    //     return 'suggestions-list-elem--home'
    //   }
    // },
    loadHistory() {
      const getStorage = JSON.parse(
        localStorage.getItem("variantsHistory") || "[]"
      );
      this.withoutLast
        ? (this.variantsHistory = getStorage.slice(0, 4))
        : (this.variantsHistory = getStorage.slice(0, 3));
      this.setVariantsHistory(this.variantsHistory)
    },
    onKey(event) {
      const { code } = event;
      if (code === "ArrowUp") {
        this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      } else if (code === "ArrowDown") {
        this.selectedIndex = Math.min(
          this.selectedIndex + 1,
          this.variants.length - 1
        );
      }
      if (code === "ArrowDown" || code === "ArrowUp") {
        const elem = this.$el.querySelector(
          `.suggestions-list-elem[data-variant-id="${this.selectedIndex}"]`
        );
        if (elem) {
          this.$el.scrollTop =
            elem.offsetTop - this.$el.offsetTop - elem.clientHeight;
        }

        event.preventDefault();
      }
      if (code === "Enter") {
        if (this.$route.name === "Search" && this.activeSearchElement === false) {
          return;
        }

        if (event.target.nodeName === 'BODY') {
          return;
        }

        if (this.$route.name === "Main") {
          return;
        }

        const index = this.selectedIndex == -1 ? 0 : this.selectedIndex;
        const variant = this.variants[index];
        variant && this.applyVariants(variant);
        event.preventDefault();
      }
    },
    checkDate(date) {
      if (date.in && date.out) {
        const currentDate = new Date();
        const dateIn = new Date(date.in);
        const dateOut = new Date(date.out);
        currentDate.setHours("00");
        currentDate.setMinutes("00");
        currentDate.setSeconds("00");
        currentDate.setMilliseconds("00");

        if (currentDate > dateIn || currentDate > dateOut) {
          return false;
        }
        return true;
      }
      return false;
    },
    handleVariantApply(variant, type, matomoKey) {
      this.applyVariants(variant, type);
      const getMatomoConfig = this.matomoConfig[matomoKey || type];
      if(getMatomoConfig) {
        this.matomoHelper(getMatomoConfig(variant.title));
      }
    },
    applyVariants(variant, listType = null) {
      const renameKey = (obj, oldKey, newKey) => {
        if(oldKey !== newKey && obj.hasOwnProperty(oldKey)) {
          Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, oldKey))
          delete obj[oldKey]
        }
      }
      if(variant.data && variant.data.type === 'hotel') {
        renameKey(variant.bbox.NE, 'lon', 'lng')
        renameKey(variant.bbox.SW, 'lon', 'lng')
      }

      if (variant.guests) {
        this.setGuests(variant.guests)
      }

      if (variant.date?.in && variant.date?.out) {
        if (this.checkDate(variant.date)) {
          this.setDateFromHistorylist({
            in: new Date(variant.date.in),
            out: new Date(variant.date.out)
          })
        }
      }


      this.setCondidateForHistory(variant)
      this.setAnaliticParamForSearchWidList({
        type: listType,
        value: variant.title
      });
      this.setSelectedVariant(variant)
      if(variant.data && variant.data.type === 'object') {
        let routeData = this.$router.resolve({name: 'Detail', params: {id: variant.data.id}});
        window.open(routeData.href, '_blank');
        return
      }
      if (variant.data && variant.data.type === "hotel") {
        const idsPayload = {
          key: "ids",
          value: variant.data.ids,
          item: [],
        };
        this.updateFilters(idsPayload);
        const hotelPayload = {
          key: "hotel",
          value: variant.title,
          item: {
            key: "hotel",
            value: variant.title,
            text: variant.title,
          },
        };
        this.updateFilters(hotelPayload);
      }
      this.$emit("applyVariant", true);
      this.isChoice = true;
      let promises = [];
      var {
        // location: { id = null, type = null },
        bbox,
        title,
        title_ext,
        point,
      } = variant;
      if (this.$route.name === "Search") {
        this.$emit('setLocation', {
          bbox,
          term: title,
          point,
        });
      } else if (this.$route.name !== "Main") {
        if (variant?.location?.id && variant?.location?.type) {
          promises.push(
            this.$store.dispatch("search/setLocation", {
              id: variant.location.id,
              type: variant.location.type,
              bbox,
              term: title,
              point,
            })
          );
        } else {
          promises.push(
            this.$store.dispatch("search/setLocation", {
              bbox,
              term: title_ext ? `${title_ext} ${title}` : title,
              point,
              title_ext
            })
          );
        }
      } else {
        point ? this.setPlace({ ...point, title }) : this.setPlace();
        this.setTerm(title);
        this.setBound(bbox);
        this.setMap();
      }
      this.redirectToSearch(promises);
      this.$emit("choice", variant);

      if(listType === "history") {
        this.setListType(listType);
      } else {
        this.setListType("");
      }
    },
    async redirectToSearch(promises) {
      if (!["Main", "Search", undefined].includes(this.$route.name)) {
        await Promise.all(promises);
        await this.$nextTick();
        let query = _.pickBy({
          occupied: this.getQuery.query.occupied,
          guests_adults: this.getQuery.query.guests_adults,
          guests_childrens: this.getQuery.query.guests_childrens,
          // id: this.getQuery.query.id,
          // type: this.getQuery.query.type,
          term: this.getQuery.query.term || this.location.title,
          point: this.getQuery.query.point,
        });
        const params = Object.entries(query)
          .map((v) => v.join("="))
          .join("&");

        await this.$nextTick();
        this.$router
          .push({
            name: "Search",
            query,
          })
          .catch((err) => {});
      }
    },
    maxHeightWrap() {
      if (typeof window === "object" && this.viewPort === "desktop") {
        let heightWindow = window.innerHeight;
        let paddingTop = this.$refs.suggestionsList.getBoundingClientRect().top;

        this.$refs.suggestionsList.style.maxHeight = `${
          heightWindow - paddingTop - 20
        }px`;
      }
    },
    format(value) {
      if (Number(value) == 0) return "";
      return Number.isInteger(value)
        ? Number(value).toLocaleString("ru-RU")
        : value;
    },
  },
  computed: {
    ...mapGetters("search", ["getQuery"]),
    ...mapState("user", ["viewPort"]),
    ...mapState("search", ["term", "guests"]),
    variantsPopular(){
      if(this.longList) {
        return variantsPopular.slice(0,10 - Number(this.variantsHistoryList.length));
      }
      if(this.variantsHistoryList.length < 3) {
        return variantsPopular.slice(0,5);
      }
      return variantsPopular.slice(0,3);
    },
    variants() {
      if (this.variantsQuery.length === 0) {
        return [...this.variantsHistory, ...this.variantsPopular];
      } else {
        return this.variantsQuery;
      }
    },
    variantsHistoryList() {
      if(!this.withoutLast) {
        return this.variantsHistory;
      } else {
        for(let item = 1; item < this.variantsHistory.length; item++) {
          this.variantsHistoryShort.push(this.variantsHistory[item]);
        }
        return this.variantsHistoryShort;
      }
    },
    variantsQueryList(){
      let res = {
        hotels: [],
        directions: []
      };

      this.variantsQuery.forEach((item) => {
        if (item.data?.type === "hotel") {
          res.hotels.push(item);
        } else {
          res.directions.push(item);
        }
      });

      return res.hotels.length || res.directions.length ? res : null;
    }
  },
  watch: {
    value: _.debounce(async function (value) {
      this.$el.scrollTop = 0;
      this.selectedIndex = -1;
      this.variantsQuery = await this.getTermSuggestionsWithBoundingBox(value);
      this.variantsQuery = this.variantsQuery.slice(0, 5);
      // this.variantsQuery = this.variantsQuery.map(variant => ({ weight: levenshtein(this.value, variant.title), variant })).sort((a,b) => a.weight - b.weight).map(v=> v.variant)
    }, 900),
  },
  data() {
    return {
      selectedIndex: -1,
      isChoice: false,
      variantsQuery: [],
      variantsHistory: [],
      variantsHistoryShort: [],
      matomoConfig: {
        directions: (title) => {
          return {
            eventCategory: "Search",
            action: 'SearchResultLocation_Select',
            eventName: {
              SearchType: 'Country/Region/City/Street/Object/Address/Metro/Other',
              SerachRequest: title,
            }
          };
        },
        hotels: (title) => {
          return {
            eventCategory: "Search",
            action: 'SearchResultHotels_Select',
            eventName: {
              SearchType: 'Country/Region/City/Street/Object/Address/Metro/Other',
              SerachRequest: title,
            }
          };
        },
        history: () => {
          return {
            eventCategory: "Search",
            action: 'History_Select'
          };
        },
        popular: (title) => {
          return {
            eventCategory: "Search",
            action: 'Popular_Select',
            eventName: title,
          };
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
$padding: 14px;

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  // border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;
  // border-radius: 10px;

  border-radius: 3px;
  box-shadow: inset 0 0 0 2px grey;
  border: 3px solid transparent;
}
.suggestions-list {
  width: 100%;
  padding: 0 4px;
  &--wrapper {
    position: absolute;
    background: white;
    padding: $padding 0;
    width: 100%;
    // max-height: calc(40px * 5 + 49px);
    // overflow-y: auto;
    // overflow-x: hidden;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08), 0px 4px 15px rgba(20, 45, 136, 0.06);
    border-radius: 20px;
    overflow: hidden;
    z-index: 10;
    .mobile & {
      @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) {
          padding-bottom: 90px;
        }
      }
    }
  }
  &__scroll{
    width: 100%;
    max-height: calc(80vh - $padding * 2);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-title {
    color: #717171;
    padding: 0px 17px 6px;
    top: 0px;
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    background: white;
    letter-spacing: 0.2px;
  }

  &-elem {
    height: 42px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-main-black);
    cursor: pointer;
    transition: all .2s;
    border-radius: 10px;
    &:hover{
      background: #F1F3FB;
    }

    &--icon{
      flex-shrink: 0;
    }
    &--title {
      white-space: nowrap;
      padding-right: 4px;
    }
    &--location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--count {
      margin-left: auto;
      padding-left: 10px;
      white-space: nowrap;
    }

    &--bottom{
      font-size: 12px;
    }
    &--date{
      color: #000;
    }
    &--date + &--guests{
      &::before{
        content: " \00B7";
        color: #000;
      }
    }

    &--history {
      display: inline-block;
      background-color: #ededed;
      padding: 5px;
      background-image: url("~/assets/img/clock-gray.svg");
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      background-position: center;
      margin-right: 12px;
    }
    &--mark {
      display: inline-block;
      // TODO ЗАМЕНИТЬ НА ШРИФТЫ
      background-image: url("~/assets/img/searchWidget.svg");
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      margin-right: 12px;
    }
    &--room {
      display: inline-block;
      background-color: #ededed;
      padding: 5px;
      background-image: url("~/assets/img/bed.svg");
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &--home {
      display: inline-block;
      background-color: #ededed;
      padding: 5px;
      background-image: url("~/assets/img/home.svg");
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  &-elem__top{
    font-size: 55px;
  }
}
.suggestions-list + .suggestions-list-title,
.suggestions-list-elem + .suggestions-list-title{
  margin-top: 10px;
}
.popular{
  .suggestions-list-elem{
    font-size: 14px;
  }
}
</style>
