{
  "currency": "₽",
  "langJS": "ru-RU",
  "datepicker": {
    "closeText": "Введу даты позже",
    "tooltip": "Введите даты поездки, чтобы увидеть цены",
    "reset": "Сбросить выбор",
    "lang": "ru-RU"
  },
  "meta": {
    "index": {
      "title": "СУТОЧНО.РУ — найдём, где остановиться: отели, квартиры, гостевые дома",
      "description": "Поможем найти и забронировать жильё посуточно в России и за рубежом. Более 320 тысяч вариантов во всех популярных направлениях для путешествий: Санкт-Петербург, Москва, Казань, Екатеринбург, Калининград, Новосибирск, Сочи, Крым, Минск, Абхазия и другие.",
      "keywords": "квартиры посуточно, квартиры на сутки, дома, коттеджи, жильё, гостиницы, сервис, бронирование, без посредников."
    },
    "search": {
      "title": "Снять квартиру посуточно в {city}, аренда квартиры на сутки {city} Суточно.ру",
      "description": "Снять квартиру напрямую у владельцев без посредников и переплаты в {city}. Выгодные цены, реальные отзывы, телефоны собственников, гарантия заселения, рейтинг жилья на Суточно.ру."
    },
    "detail": {
      "description": "Отличное жилье на {address} в городе {city}. Забронируй прямо сейчас на Суточно.ру",
      "perDay": "посуточно",
      "ad": "объявление",
      "sutochno": "Суточно.ру"
    },
    "review":{
      "title": "Оцените проживание, шаг 1 — Суточно.ру"
    },
    "reviewComment":{
      "title": "Оцените проживание, шаг 2 — Суточно.ру"
    },
    "reviewLocation":{
      "title": "Оставьте отзыв о локации — Суточно.ру"
    },
    "hotel": {
      "sutochno": "Суточно.ру",
      "price": "цены, фото, отзывы",
      "booking": "Забронировать номер в отеле",
      "in": "в"
    },
    "404": {
      "title": "Страница не найдена — Суточно.ру"
    }
  },
  "validation": {
    "alpha": "Поле может содержать только буквы",
    "alpha_dash": "Поле может содержать только буквы, цифры и дефис",
    "alpha_num": "Поле может содержать только буквы и цифры",
    "alpha_spaces": "Поле может содержать только буквы и пробелы",
    "between": "Поле должно быть между {min} и {max}",
    "confirmed": "Поле не совпадает с {target}",
    "digits": "Поле должно быть числовым и точно содержать {length} цифры",
    "dimensions": "Поле должно быть {width} пикселей на {height} пикселей",
    "email": "Введите корректный электронный адрес",
    "excluded": "Поле должно быть допустимым значением",
    "ext": "Поле должно быть действительным файлом. ({args})",
    "image": "Поле должно быть изображением",
    "oneOf": "Поле должно быть допустимым значением",
    "integer": "Поле должно быть целым числом",
    "length": "Длина поля должна быть {length}",
    "max": "Поле не может быть более {length} символов",
    "max_value": "Поле должно быть {max} или менее",
    "mimes": "Поле должно иметь допустимый тип файла. ({args})",
    "min": "Поле должно быть не менее {length} символов",
    "min_value": "Поле должно быть {min} или больше",
    "numeric": "Поле должно быть числом",
    "regex": "Поле имеет ошибочный формат",
    "required": "Поле обязательно для заполнения",
    "required_if": "Поле обязательно для заполнения",
    "size": "Поле должно быть меньше, чем {size}KB",
    "min_phone" : "Введите корректный номер телефона",
    "settlementAccount" : "Введите корректный расчетный счёт"
  },
  "cities": {
    "spb": "Санкт-Петербург",
    "msk": "Москва",
    "sochi": "Сочи",
    "kzn": "Казань",
    "minsk": "Минск",
    "nn": "Нижний Новгород",
    "kaliningrad": "Калининград",
    "krasnodar": "Краснодар"
  }
}
