import gaCreateProduct from "./gaCreateProduct";
import gaChangeForm from "./gaChangeForm";
import gaEventBooking from "./gaEventBooking";
import gaAddProduct from "./gaAddProduct";
import ymChangeForm from "./ymChangeForm";
import gaClickBenefit from "./gaClickBenefit";
import gaClickMoreReviews from "./gaClickMoreReviews";
import gaScrollToReview from "./gaScrollToReview";
import gaSortReviews from "./gaSortReviews";
import gaLoginStatus from "./gaLoginStatus";

/**
 * Работа с карточкой объекта (детальной страницей)
 *
 * Desktop path: @/components/desktop/Detail/
 * Mobile path: @/components/mobile/Detail/
 *
 * @module Detail
 */
export default {
  gaCreateProduct,
  gaChangeForm,
  gaEventBooking,
  gaAddProduct,
  ymChangeForm,
  gaClickBenefit,
  gaClickMoreReviews,
  gaScrollToReview,
  gaSortReviews,
  gaLoginStatus,
};
