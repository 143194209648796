<i18n lang="json">
{
	"ru": {
    "titleOpen":"Выберите возраст ребёнка",
    "desc":{
      "-2":"по согласованию",
      "-1":"от 0 до 17 лет",
      "0":"нельзя с детьми",
      "any": "нельзя младше {age}"
    },
    "notAllowed":"нельзя с детьми",
    "age": "до года | {n} год | {n} года | {n} лет",
    "ages": " {n} год | {n} года | {n} лет",
    "placeholder": "Выберите возраст",
    "label": "Возраст",
    "addChild":"Дети",
    "upToYears": "до 17 лет",
    "freeBabies": "но можно с младенцами"
	},
	"en": {
    "titleOpen":"Choose your child's age",
    "desc":{
      "-2": "by agreement",
      "-1": "0 to 17 years old",
      "0": "not allowed with children",
      "any": "only with {age}"
    },
    "age": "up to a year | {n} year | {n} years | {n} years",
    "ages": "{n} year | {n} years | {n} years",
    "placeholder": "Select age",
    "label": "Age",
    "addChild":"Add children",
    "upToYears": "up to 17 years old",
    "freeBabies": "but it is possible with infants"
	}
}
</i18n>
<template>
  <div class="select-guests__add-child add-child">
    <div class="add-child__text">
      <strong>{{ t("addChild") }}</strong>
      <span
        :class="{
          restriction: isRestriction,
          'hint-popup  hint-popup__bottom': popoverText,
        }"
        :data-hint="popoverText"
      >
        {{ getDescText }}<template v-if="freeBabiesText">,
          <br />
          {{ freeBabiesText }}
        </template>
      </span>
    </div>
    <div class="add-child__editing">
      <UIButton
        class="add-child__button"
        buttonTheme="pale-blue"
        :disabled="!children?.length"
        @click.prevent.stop="setChildCount(true)"
      >
        <div class="add-child__button-main">
          <span class="icon-app-minus" ></span>
        </div>
      </UIButton>
      <span class="add-child__value">{{ children.length }}</span>
      <UIButton
        class="add-child__button"
        buttonTheme="pale-blue"
        @click.prevent.stop="setChildCount(false)"
      >
        <div class="add-child__button-main">
          <span class="icon-app-plus" ></span>
        </div>
      </UIButton>
    </div>
  </div>
  <UISelect
    class="select-guests__select"
    v-for="(child, index) of children"
    :key="index"
    :modelValue="parseInt(children[index])"
    :options="getOptions(children[index])"
    :placeholder="t('placeholder')"
    :label="t('label')"
    :error="childrenWithoutAgeError && (child === -1 || child === null)"
    :isHasRemover="true"
    @update:modelValue="$emit('changeChildAge', $event, index)"
    @removeSelect="$emit('removeChild', index)"
  />
</template>

<script>
import _ from "lodash";
import UISelect from "@/components/UIKit/UISelect";
import UIButton from "@/components/UIKit/UIButton";
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "ChildrenSelect",
  components: {
    UISelect,
    UIButton,
  },
  emits: ["addChild", "changeChildAge", "removeChild"],
  props: {
    children: {
      type: Array,
      default: [],
    },
    restriction: {
      // 0 запрещено
      // -1 разрешено
      // -2 по зпросу
      // 1,2,3,4,5... ограничение по возрасту
      type: Number,
      default: -1
    },
    popoverText: {
      type: String,
      default: "",
    },
    freeBabies: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed:{
    ...mapState("search", ["childrenWithoutAgeError"]),
    isRestriction() {
      return this.restriction !== -1 && !(this.restriction === 2 && this.freeBabies);
    },
    getDescText() {
      if (this.restriction === 2 && this.freeBabies) {
        return this.t("desc.-1");
      }
      if (this.restriction > 0) {
        return this.t("desc.any", {
          age: this.t("ages", this.restriction),
        });
      }
      return this.t(`desc.${this.restriction}`)
    },
    blockInfant() {
      return this.children.filter((age) => age !== null && age < 2).length > 3;
    },
    freeBabiesText() {
      if (!this.freeBabies || this.restriction <= 2) {
        return null;
      }
      return this.t("freeBabies")
    }
  },
  watch: {
    isOpen(val) {
      this.$emit("isOpen", val);
    },
  },
  methods: {
    setChildCount(isRemove) {
      this.$emit('addChild', isRemove);
      matomoHelper({
        eventCategory: "Search",
        eventAction: !isRemove ? "Children_ClickPlus" : "Children_ClickMinus",
      });
    },
    selectItem(item) {
      this.isOpen = false;
      this.$emit("update:modelValue", item.value);
    },
    deleteItem() {
      this.$emit("delete");
    },
    getOptions(age) {
      return _.range(0, 18).map((c) => {
        const disabled = this.blockInfant && (age === null || age >= 2) && c < 2;
        return {
          text: this.t("age", c),
          value: c,
          disabled,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-guests__select{
  margin-top: 15px;
}
.select-guests__select + .select-guests__select{
  margin-top: 16px;
}
.add-child {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__editing{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 120px;
    width: 100%;
  }

  &__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  &__text {
    strong {
      display: block;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      font-size: 12px;
      line-height: 16px;
    }
    .restriction{
      border-bottom: 1px dashed #5a5d63;
    }
  }

  &__button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50%;
  }
  &__button-main{
    display: flex;
    align-items: center;

    span {
      font-size: 18px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #d8d8d8;
  border: 3px solid transparent;

  &:hover {
    box-shadow: inset 0 0 0 2px #999999;
  }
}
</style>
