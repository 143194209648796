<i18n lang="json">
{
  "ru": {
    "pets": "С питомцами"
  },
  "en": {
    "pets": "With pets"
  }
}
</i18n>

<template>
  <GuestsCounter v-model="adults" class="adults" />
  <hr />
  <ChildrenSelect
    :children="guests.childrens"
    :free-babies="freeBabies"
    @addChild="addChild"
    @changeChildAge="changeChildAge"
    @removeChild="removeChild"
  />
  <hr />
  <div class="select-guests__pets pets">
    <span class="pets-text">{{ t("pets") }}</span>
    <UISwitcher v-model="petsValue" />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import GuestsCounter from "../GuestsCounter";
import ChildrenSelect from "../ChildrenSelect";
import UISwitcher from "@/components/UIKit/UISwetcher";
import { mapGetters } from "vuex";

export default {
  name: "LightGuestOption",
  components: {
    GuestsCounter,
    ChildrenSelect,
    UISwitcher,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      adults: 1,
      children: [],
    };
  },
  props: {
    guests: {
      type: Object,
      default: () => {
        return {
          adults: 1,
          childrens: [],
          pets: {
            value: false,
            description: "",
          },
        };
      },
    },
  },
  emits: ["changeChildren", "changeAdults", "changePetsValue"],
  watch: {
    adults(val) {
      this.$emit("changeAdults", val);
    },
    children(val) {
      this.$emit("changeChildren", val);
    },
    guests: {
      deep: true,
      handler(guests) {
        this.adults = guests?.adults || 1;
        this.children = guests?.childrens || [];
      },
    },
  },
  created() {
    this.adults = this.guests?.adults || 1;
    this.children = this.guests?.childrens || [];
  },
  computed: {
    ...mapGetters("detail", ["getObjectProperties"]),
    petsValue: {
      get() {
        return this.guests?.pets?.value || false;
      },
      set(withPets) {
        this.$emit("changePetsValue", withPets);
        matomoHelper({
          eventCategory: "Search",
          eventAction: withPets ? "Pets_SwitchOn" : "Pets_SwitchOff",
        });
      },
    },
    freeBabies() {
      const properties =
        this.getObjectProperties?.accommodation_rules?.properties;
      const isFreeBabies = properties?.find(
        (item) => item.name === "free_babies"
      )?.value;
      return isFreeBabies === "false" ? false : Boolean(isFreeBabies);
    },
  },
  methods: {
    addChild(remove = false) {
      if (this.children.length >= 20 && !remove) {
        return false;
      }
      if (remove) {
        let target = [...this.children];
        if (target.length > 1) {
          target.pop();
        } else {
          target = [];
        }
        this.children = target;
        return;
      }
      this.children = [...this.children, null];
    },
    changeChildAge(value, index) {
      this.children = this.children.map((elem, i) => {
        if (index === i) {
          return value;
        }
        return elem;
      });
    },
    removeChild(index) {
      this.children = this.children.filter((elem, i) => i !== index);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-guests {
  &__pets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
  }
}

hr {
  margin: 15px 0;
  border-top: 1px solid #e7e9f3;
  transform: translateX(-8%);
  width: 119%;
}
</style>
