// [MDN Reference](https://developer.mozilla.org/docs/Web/API/Storage)
const isDev = process.env.NODE_ENV === "development";
const warnServer = () => isDev ? console.log('\x1b[33m%s\x1b[0m', "Browser storage not working on server") : {};
const STORAGE = {
  length: 0,
  clear: warnServer,
  getItem: warnServer,
  key: warnServer,
  removeItem: warnServer,
  setItem: warnServer,
}

export const sessionStorage = process.server ? STORAGE : window.sessionStorage;
export const localStorage = process.server ? STORAGE : window.localStorage;
