import { Orders, Objects, Users } from "@/components/api/";
import { formatSafari } from "@/utils/format";
import _ from "lodash";
import { Booking, Cian, GLOBAL as Global } from "@/utils/gtm/";
import sutochnoMetrika from "@/utils/sutochnoMetrika";

// initial state
const state = {
  /** Данные получаемые из getPricesAndAvailabilites */
  bookingPrice: null,
  bookingPriceError: [],

  /** Занятость объекта, получаемая из getOrdersByObject */
  employment: null,

  /** Параметры получаемые из getOrderDraft */
  params: null,
  requisites: null,
  requisitesUser: [],
  objectData: null,
  mobiles: {},
  paramsWarning: {},
  loadBookingPrice: false,
  loadUpdateOrderDraft: false,
  bookingNow: false,
  canHold: false, //разрешено ли холдирование средств
  loadBooking: false,
  bookingTo: null,
  bookingComplete: null,
  bookingToIntersect: null,
  bookingToErrors: [],
  authData: null,
  password: "",
  validateContacts: false,
  viewPhoneData: null,
  getPassword: false,
  draftType: undefined,
  errorDate: "",
  warningInfo: {
    is_doc: {
      warning: "",
      error: "",
      translit: "",
    },
    is_pets: {
      warning: "",
      error: "",
      translit: "",
    },
    target: {
      warning: "",
      error: "",
      translit: "",
    },
    time_begin: {
      warning: "",
      error: "",
      translit: "",
    },
    time_end: {
      warning: "",
      error: "",
      translit: "",
    },
    guests: {
      warning: "",
      error: "",
      translit: "",
    },
  },
  phoneSelected: null,
  viewAsidePhone: false,
  aproovePhone: false,
  maskPhone: null,
  simpleBookingLoading: false,
  petsDescriptionError: false,
  beforeHours: undefined,
  openCalendar: false,
  nameEntered: true,
  isErrorUserName: false,
  lastParamsForBookingPriceData: {},
  legalInfo: null,
  legalId: null,
};

// getters
const getters = {
  isNoValidPetsDesc: (state) => {
    // введено ли описание питомца?
    // описание питомца должно быть введено если по правилу разрешение на питомца идет только по запросу
    // console.log('state.params.is_pets', state.params.is_pets)
    // console.log('state.params.pets.trim()', state.params.pets.trim())
    // console.log('state.objectData.pets', state.objectData.pets)
    if(state.params.is_pets && !state.params.pets.trim()){
      if(state.objectData.pets === 2 || state.objectData.pets === undefined){
        return true
      }
    }
    return false
  },
  isDisabledBooking: (state) => {
    if (state.errorDate) return true;
    if (
      Object.values(state.warningInfo).findIndex((item) => item.error) != -1
    ) {
      return true;
    } else {
      return false;
    }
  },
  orderError: (state, getters) => {
    if (getters.isDisabledBooking && !state.errorDate) {
      const WARNING_INFO = state.warningInfo;
      return Object.entries(WARNING_INFO).reduce((acc, [key, val]) => {
        if (val.error) acc.push(key);
        return acc;
      }, []);
    }
    return [];
  },
  bookingPrice: (state) => {
    if (!state.bookingPrice) return null;
    // цену за поздний отъезд, ставим возле раннего заезда
    const bookingPrice = JSON.parse(JSON.stringify(state.bookingPrice))
    let indexEarly = bookingPrice?.detail?.findIndex(item => item.type === "early")
    let indexLate = bookingPrice?.detail?.findIndex(item => item.type === "late")

    if (indexLate >= 0) {
      let late = bookingPrice.detail.find(item => item.type === "late")
      bookingPrice.detail.splice(indexLate, 1)
      bookingPrice.detail.splice(indexEarly+1, 0, late)
    }
    return bookingPrice;
  },
  bookingPriceError: (state) => {
    if (!state.bookingPriceError) return null;
    return state.bookingPriceError;
  },
  employment: (state) => {
    if (!state.employment) return null;
    return state.employment;
  },
  requisitesUser: (state) => {
    if (!state.requisitesUser) return null;
    return state.requisitesUser;
  },
  isNameEntered: (state) => {
    if (!state.nameEntered) return null;
    return state.nameEntered;
  },
  params: (state) => {
    if (!state.params) return null;
    return {
      ...state.params,
      time_begin: state.params.date_begin
        ? state.params.date_begin.getHours()
        : null,
      time_end: state.params.date_end ? state.params.date_end.getHours() : null,
    };
  },
  paramsWarning: (state) => {
    if (!state.paramsWarning) return null;
    return state.paramsWarning;
  },
  objectData: (state) => {
    if (!state.objectData) return null;
    return state.objectData;
  },
  userMobiles: (state) => {
    if (!state.mobiles) return {};
    return state.mobiles;
  },
  loadBookingPrice: (state) => {
    if (!state.loadBookingPrice && !state.bookingPrice) return false;
    return state.loadBookingPrice;
  },
  loadUpdateOrderDraft: (state) => {
    if (!state.loadUpdateOrderDraft) return false;
    return state.loadUpdateOrderDraft;
  },
  bookingNow: (state) => {
    return state.bookingNow;
  },
  occupied: (state) => {
    if (!state.params) return null;
    if (
      state.params.date_begin instanceof Date &&
      state.params.date_end instanceof Date
    ) {
      let format = (date) => {
        let month = (date.getMonth() + 1).toString().padStart(2, 0),
          day = date.getDate().toString().padStart(2, 0),
          year = date.getFullYear();
        return year + "-" + month + "-" + day;
      };

      return (
        //get data in format YYYY-MM-DD
        format(state.params.date_begin) + ";" + format(state.params.date_end)
      );
    } else {
      return null;
    }
  },
  currencyCalc: (state, getters, rootState) => {
    if (state.bookingPrice && state.bookingPrice.currency) {
      return {
        id: state.bookingPrice.currency.id,
        title: state.bookingPrice.currency.code,
      };
    } else {
      return rootState.user.currency;
    }
  },
  getNameObject: (state) => {
    if (!state.objectData) {
      return null;
    }
    const objectData = state.objectData;

    if (objectData?.hotel?.id) {
      const { hotel_category, type_title } = objectData;
      if (type_title) {
        return type_title;
      }
      if (typeof hotel_category.title !== "string") {
        return "";
      }
      return `${hotel_category?.type} ${hotel_category?.title}`;
    }

    return objectData?.name_object || "";
  }
};

// actions
const actions = {
  setBookingPriceData(
    { commit, getters },
    {
      guests = null,
      ages = null,
      time_begin = null,
      time_end = null,
      date_begin = null,
      date_end = null,
      objects = null,
      rooms_cnt = null,
      currency_id = 1,
      no_time = 1,
      max_rooms = {},
      cancelToken = null
    }
  ) {
    commit("setLoadBookingPrice", true);
    // const { object_id: objID, rooms_cnt: roomsCount } = getters.params
    //  Object.entries(max_rooms).length === 0 ? { [objID]: roomsCount } : max_rooms,
    return Objects.getPricesAndAvailabilities({
      guests,
      ages,
      time_begin,
      time_end,
      date_begin,
      date_end,
      objects,
      rooms_cnt,
      max_rooms,
      currency_id,
      no_time,
      cancelToken
    })
      .then((response) => {
        const PRICE_OBJECT = response.data?.data?.objects[0];
        if (PRICE_OBJECT) commit("setBookingPriceData", PRICE_OBJECT.data);
        setTimeout(() => {
          commit("setLoadBookingPrice", false);
        }, 100);
        commit("setErrorDate", PRICE_OBJECT.errors[0]);
        if (typeof objects === "number")
          commit("setBookingPriceError", PRICE_OBJECT.errors);
        return response.data.data;
      })
      .catch((error) => {
        console.warn("Ajax error: Objects.getPrices, ", error);
      });
  },
  setBookingPriceError({ commit }, { error = [] }) {
    // commit("setBookingPriceData", []);
    commit("setBookingPriceError", error);
  },
  setEmploymentCalendar({ commit }, { object_id }) {
    Orders.getOrdersByObject(object_id)
      .then((response) => {
        if (response.data && response.data.data)
          commit(
            "setEmploymentCalendar",
            response.data.data.calendars.map((item) => ({
              from: new Date(formatSafari(item.date_begin)),
              to: new Date(formatSafari(item.date_end)),
            }))
          );
      })
      .catch((error) => {
        console.warn("Ajax error: Orders.getOrdersByObject, ", error);
      });
  },

  async setParams({ commit }, { draftCode = null }) {
    let setParams;
    await Orders.getOrderDraft({ draftCode })
      .then((response) => {
        setParams = response.data;
        return {
          ...response.data.data,
          ...response.data.data.data,
        };
      })
      .then((response) => {
        if (response) {
          const unDate = "0000-00-00 00:00:00";
          response.date_begin =
            response.date_begin && response.date_begin != unDate
              ? new Date(formatSafari(response.date_begin))
              : null;
          response.date_end =
            response.date_end && response.date_end != unDate
              ? new Date(formatSafari(response.date_end))
              : null;
          if (response.draft_type)
            commit(
              "setBookingNow",
                (response.draft_type === "mb" || response.draft_type === "hot") ? true : false
            );
        }
        commit("setParams", response);
      });
    return setParams;
  },
  updateParams({ commit, state }, PAYLOAD) {
    // UPDATE STORE
    const BLEND = { ...state.params, ...PAYLOAD };
    return commit("updateParams", BLEND);
  },
  updateOrder({ commit, state, getters }, PAYLOAD) {
    commit("setLoadUpdateOrderDraft", true);
    return Orders.updateOrderDraft(getters.params, PAYLOAD)
      .then((response) => {
        commit("setLoadUpdateOrderDraft", false);
        commit("setDraftType", response.data.data.draft_type);
        if (state.warningInfo[PAYLOAD.name]) {
          let textError = "";

          if (PAYLOAD.name == "guests") {
            textError = response.data.errors.length
              ? response.data.errors[0].replace(/\.$/gm, "")
              : "";
          } else {
            textError = response.data.errors.length ? true : false;
          }

          let valWar = {
            warning: response.data.actions.warning ? true : false,
            error: textError,
            translit: response.data.data.translit
              ? response.data.data.translit
              : "",
          };
          let fieldName = PAYLOAD.name == "pets" ? "is_pets" : PAYLOAD.name;
          commit("updateWarningInfo", { field: fieldName, val: valWar });
        }

        if (response.data.actions && response.data.actions.warning) {
          commit(
            "setParamsWarning",
            response.data.actions.warning.reduce((acc, w) => {
              acc[w.type] = w.text;
              return acc;
            }, {})
          );
        }

        return response;
      })
      .catch((error) => {
        commit("setLoadUpdateOrderDraft", false);
        console.warn("Ajax error:", error);
      });
  },
  setRequisites({ commit }, { orderCode = null, inn = null, bik = null }) {
    Users.suggestCompany({ orderCode, inn, bik })
      .then((response) => {
        if (response.data && response.data.data)
          commit("setRequisites", response.data);
      })
      .catch((error) => {
        console.warn(
          "Ошибка при получении данных о черновике",
          "Ajax error:",
          error
        );
      });
  },
  addRequisites(
    { commit },
    { orderCode = null, params = null, inn = null, bik = null }
  ) {
    return Users.addUserRequisite({ orderCode, params, inn, bik })
      .then((response) => {
        if (response.data && response.data.data)
          commit("setRequisitesUser", response.data.data);
        return response;
      })
      .catch((error) => {
        console.warn(error);
      });
  },
  getRequisites({ commit }, { orderCode = null }) {
    Users.getUserRequisites(orderCode)
      .then((response) => {
        if (response.data && response.data.data)
          commit("setIdLegal", response.data.data.id_legal_person);
        commit("getRequisitesUser", response.data.data.user_requisites);
      })
      .catch((error) => {
        console.warn(error);
      });
  },
  getObject({ commit }, { id = null, currency_id = null }) {
    Objects.getObject(id, currency_id)
      .then((response) => response.data.data.object)
      .then((response) => {
        const {
          media,
          location: { location, address, lat, lng },
          title,
          properties,
          extprices,
          hotel,
          type_title,
          position,
          id,
          hotel_category,
          owner,
        } = response;

        const dateIn = Number(
          properties.check_in_out_rules
            ? properties.check_in_out_rules.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
            : properties.additional_payment.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
        );

        const dateOut = Number(
          properties.check_in_out_rules
            ? properties.check_in_out_rules.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{1,2}/)
            : properties.additional_payment.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{2}/)
        );

        const fc = properties?.hidden_properties?.properties?.find(
          (item) => item.name == "fc"
        );
        const checkInEarly = properties?.check_in_out_early_rules?.properties?.find(
          (item) => item.name == "check_in_early"
        );

        let dateInEdge = properties?.check_in_out_rules?.properties
          .find((c) => c.name == "check_in_edge")
          ?.value.toString()
          .replace("-1", "")
          .split(":")[0];

        let dateOutEdge = properties?.check_in_out_rules?.properties
          .find((c) => c.name == "check_out_edge")
          ?.value.toString()
          .replace("-1", "")
          .split(":")[0];

        const pets = properties?.pay_attention?.properties?.find(
          (g) => g.name == "pets"
        );

        const party = properties?.pay_attention?.properties?.find(
          (g) => g.name == "party"
        )

        const BLEND = {
          id,
          media: media.map((m) => m.image_url.replace("source", "medium")),
          location: {
            city: location,
            address,
            lat,
            lng,
          },
          title,
          maxGuests: properties.basic_properties.properties.find(
            (g) => g.name == "max_guests"
          ).value,
          roomsCnt:
            properties?.basic_properties?.properties?.find(
              (r) => r.name == "rooms"
            )?.value || 1,
          date: {
            in: dateIn >= 0 ? dateIn : 14,
            out: dateOut >= 0 ? dateOut : 11,
            inEdge: dateInEdge ? Number(dateInEdge) : null,
            outEdge: dateOutEdge ? Number(dateOutEdge) : null,
          },
          pets: pets ? pets.value : false,
          party: party ? party.value : false,
          defaultPrice: extprices.find((p) => p.interval_start == 0)?.value,
          hotel,
          type_title,
          position,
          fc: fc ? fc.value : false,
          smoking: properties?.accommodation_rules?.properties.find(
            (g) => g.name == "smoking"
          )?.value,
          checkInEarly: checkInEarly ? checkInEarly.value : false,
          pets: properties?.accommodation_rules?.properties.find(
            (g) => g.name == "pets"
          )?.value,
          hotel_category,
          free_babies: properties?.accommodation_rules?.properties?.find(
            (g) => g.name === "free_babies"
          )?.value,
          name_object: properties?.enter_name?.properties?.find(
            (item) => item.name === "name_object"
          )?.value,
          owner
        };
        commit("getObject", BLEND);
      })
      .catch((error) => {
        console.warn(error);
      });
  },
  getMobiles({ commit }) {
    return Users.getMyMobiles()
      .then((response) => {
        const LIST = Object.values(response.data.data.list);
        commit("getMobiles", LIST);
        return LIST;
      })
      .catch((error) => {
        console.warn(error);
      });
  },
  bookingToAction({ commit, state, dispatch }, { order_code, no_matter, cost, precost }) {
    commit("loadBookingMutate", true);
    return Orders.bookingTo({
      order_code,
      no_matter,
      cost,
      precost,
    })
      .then((response) => {
        commit("bookingToMutate", response);
        commit("loadBookingMutate", false);
        if (response.data?.data?.orderId) {
          const metrikaObj = {
            yaClid: "",
            glClid: "",
            orderId: response.data.data.orderId,
            dateAdd: new Date()
          }

          const promises = [Global.ymClentId(), Global.gaGetClientId()];

          Promise.allSettled(promises).then(([yaClid, glClid]) => {
            metrikaObj.yaClid = yaClid.value;
            metrikaObj.glClid = glClid.value;

            sutochnoMetrika.booking({
              ...metrikaObj
            });
          })

          dispatch("matomoBookingAnalytics");
        }
        return response;
      })
      .catch((error) => {
        console.warn("Ajax error: ", error);
        commit("loadBookingMutate", false);
        return error
      });
  },
  authAction(
    { commit },
    { name, value, password, status, forgot, persistent, terms, noValid = 0, transport_notice }
  ) {
    if (!noValid) commit("validateContactsMutate", true);
    return Users.auth({
      name,
      value,
      password,
      status,
      forgot,
      persistent,
      terms,
      transport_notice
    })
      .then((response) => {
        commit("authDataMutate", response.data);
        if (!noValid) commit("validateContactsMutate", false);
        return response;
      })
      .catch((error) => {
        console.warn("Ajax error: ", error);
      });
  },
  codeAction({ commit },payload) {
    return Users.code(payload)
      .then((response) => {
        // commit("codeMutate", response.data);
        return response;
      })
      .catch((error) => {
        console.log("CodeAction error: ", error);
      });
  },
  bookingAnalytics({ getters, rootState }) {
    Booking.gaEventBooking(
      getters.objectData?.location?.city,
      getters.bookingPrice?.price
    );
    Booking.ymSendForm();
    const variantObject = getters.objectData?.hotel ? getters?.objectData?.hotel_category?.title : `${getters.objectData?.roomsCnt} (комнат)`;
    const categoryObject = getters.objectData?.hotel ? "Отельный объект" : "Отдельный объект";
    let nameObject = "";
    if (getters.objectData?.hotel) {
      const title = getters.objectData?.hotel?.title;
      const typeHotel = getters.objectData?.hotel?.type;
      const titleObject = title ? `${typeHotel} ${title}` : typeHotel;

      nameObject = String(titleObject);
    } else {
      // eslint-disable-next-line no-unused-vars
      nameObject = getters.objectData?.type_title;
    }
    Booking.gaSendProduct(
      getters.params?.object_id,
      nameObject,
      getters.bookingPrice?.price,
      getters.objectData?.location?.city,
      categoryObject,
      getters.objectData?.type_title,
      variantObject,
      getters.objectData?.position,
      getters.bookingPrice?.cnt,
      getters.params?.guests.length
    );
    let isWebview = false;
    if (typeof location === "object" && location.search !== "") {
      isWebview = location.search.includes("webview");
    }
    if (rootState?.user?.whitelabel?.name === "domclick" && !isWebview) {
      Booking.ymSendFormDomclick();
    }
    if (rootState?.user?.whitelabel?.name === "cian") {
      const gtmProducts = [{"id": Number(getters.params.object_id)}];
      const gtmStartDate = getters.bookingPrice.date_begin.split(' ')[0];
      const gtmEndDate = getters.bookingPrice.date_end.split(' ')[0];
      const gtmPrice = getters.bookingPrice?.price;
      const gtmBookingId = rootState?.booking?.bookingTo?.data?.data?.order_id;
      if (gtmStartDate) {
        gtmProducts[0].startDate = gtmStartDate;
      }
      if (gtmEndDate) {
        gtmProducts[0].endDate = gtmEndDate;
      }
      if (gtmPrice) {
        gtmProducts[0].price = gtmPrice;
      }
      if (gtmBookingId) {
        gtmProducts[0].bookingId = gtmBookingId;
      }

      Cian.gtmPartnerEvent('Booking', {
        pageType: "Card"
      }, gtmProducts);
    }
  },
  async getProperties({commit}, id) {
    return await Objects.getProperties(id)
      .then(res => {
        if (res?.data?.data?.properties) {
          const beforeHours = res.data.data.properties.find(item => item.name === "before_hours")
          const beforeHoursVal = beforeHours ? beforeHours.value : undefined
          commit("setBeforeHours", beforeHoursVal);
          return res
        }
      })
      .catch(err => console.log(err))
  },
  matomoBookingAnalytics({ rootGetters, getters }) {
    const objectData = rootGetters["detail/getObjectData"] ? rootGetters["detail/getObjectData"] : getters.objectData;
    const objectPrice = rootGetters["detail/getObjectPrice"] ? rootGetters["detail/getObjectPrice"] : getters.bookingPrice;
    const categoryObject = objectData?.hotel ? "Отельный объект" : "Отдельный объект";
    const nameObject = rootGetters["detail/getNameObject"] ? rootGetters["detail/getNameObject"] : getters.getNameObject;
    const city = objectData.location?.city?.title || objectData.location?.city || objectData.location?.location;

    matomoHelper({
      eventCategory: "Booking",
      eventAction: "Booking",
      eventName: {
        "productSKU": objectData?.id,
        "productName": nameObject,
        "categoryName": `${city} ${categoryObject}`,
        "price": objectPrice?.price || objectPrice?.price_default,
        "quantity": 1
      },
    })
  },
  setLegalInfo({ commit }, formData) {
    return Users.getLegalInfo(formData)
      .then(response => {
        if (response.data?.data) {
          commit("setLegalInfo", response.data.data);
        } else {
          console.warn("getLegalInfo error", response);
        }
        return response;
      })
      .catch(error => {
        commit("setLegalInfo", { error });
      })
  }
};

// mutations
const mutations = {
  setOpenCalendar(state, value){
    state.openCalendar = value;
  },
  setPetsDescriptionError(state, value){
    state.petsDescriptionError = value
  },
  setBookingNow(state, value) {
    state.bookingNow = value;
  },
  updateParams(state, value) {
    state.params = value;
  },
  setParams(state, value) {
    state.params = value;
  },
  setParamsWarning(state, value) {
    state.paramsWarning = value;
  },
  /** Мутации для изменения, добавления и удаления гостей в state.params.guests */
  updateGuests(state, value) {
    state.params.guests[value.item] = value.value;
  },
  deleteGuests(state, value) {
    state.params.guests.splice(value, 1);
  },
  addGuests(state, value) {
    state.params.guests.push(value);
  },

  setBookingPriceData(state, value) {
    state.bookingPrice = value;
  },
  setBookingPriceError(state, value) {
    state.bookingPriceError = value;
  },
  setEmploymentCalendar(state, value) {
    state.employment = value;
  },
  setLoadBookingPrice(state, value) {
    state.loadBookingPrice = value;
  },
  setLoadUpdateOrderDraft(state, value) {
    state.loadUpdateOrderDraft = value;
  },

  setRequisites(state, value) {
    state.requisites = { data: value.data, errors: value.errors };
  },
  clearRequisites(state) {
    state.requisites = null;
  },
  setRequisitesUser(state, value) {
    state.requisitesUser[state.requisitesUser.length] = value.result;
  },
  getRequisitesUser(state, value) {
    state.requisitesUser = value;
  },
  setIdLegal(state, value) {
    state.params.id_legal_person = value;
  },
  getObject(state, value) {
    state.objectData = value;
  },
  viewPhoneDataMutate(state, value) {
    state.viewPhoneData = value;
  },
  getMobiles(state, value) {
    state.mobiles = value;
  },

  loadBookingMutate(state, value) {
    state.loadBooking = value;
  },
  bookingToMutate(state, value) {
    state.bookingTo = value;
  },
  setBookingComplete(state, payload) {
    state.bookingComplete = payload;
  },
  clearBookingComplete(state) {
    state.bookingComplete = null;
  },
  setBookingToErrors(state, errors) {
    const arr = Array.isArray(errors) ? errors : Object.values(errors);
    if (arr.length) {
      arr.map((v) => state.bookingToErrors.push(v));
    }
  },
  clearBookingToErrors(state) {
    state.bookingToErrors = [];
  },
  setBookingToIntersect(state, message) {
    state.bookingToIntersect = message;
  },
  clearBookingToIntersect(state) {
    state.bookingToIntersect = null;
  },
  authDataMutate(state, value) {
    state.authData = value;
  },
  passwordMutate(state, value) {
    state.password = value;
  },
  validateContactsMutate(state, value) {
    state.validateContacts = value;
  },
  getPasswordMutate(state, value) {
    state.getPassword = value;
  },
  updateWarningInfo(state, value) {
    state.warningInfo[value.field] = value.val;
  },
  setDraftType(state, value) {
    state.draftType = value;
  },
  setErrorDate(state, value) {
    state.errorDate = value;
  },
  setCanHold(state, value) {
    state.canHold = value;
  },
  selectPhoneMutate(state, value) {
    state.phoneSelected = value;
  },
  viewAsidePhoneMutate(state, value) {
    state.viewAsidePhone = value;
  },
  aproovePhoneMutate(state, value) {
    state.aproovePhone = value;
  },
  setMaskPhone(state, value) {
    state.maskPhone = value;
  },
  simpleBookingLoadingMutate(state, value) {
    state.simpleBookingLoading = value
  },
  setBeforeHours(state, value) {
    state.beforeHours = value;
  },
  setNameEntered(state, value) {
    state.nameEntered = value;
  },
  setIsErrorUserName(state, value) {
    state.isErrorUserName = value;
  },
  setLastParamsForBookingPriceData(state, value) {
    state.lastParamsForBookingPriceData = value;
  },
  setLegalId(state, payload) {
    state.legalId = payload;
  },
  setLegalInfo(state, payload) {
    state.legalInfo = payload;
  },
};

export default {
  namespaced: true,
  namespace: "booking",
  state,
  getters,
  actions,
  mutations,
};
