<i18n>
{
	"ru": {
		"titleHistory": "История поиска",
		"titlePopular": "Популярные направления",
    "titleHotels": "Гостиницы"
	},
	"en": {
    "titleHistory": "Search history",
    "titlePopular": "Popular destinations",
    "titleHotels": "Hotels"
	}
}
</i18n>
<template>
  <div class="location-variants">
    <template v-if="variantsQueryList">
      <template v-if="variantsQueryList.directions">
        <ul class="location-variants__list location-list">
          <li
            v-for="(variant, index) in variantsQueryList.directions"
            :key="index"
          >
            <LocationItem
              :class="{ 'active-item': index === selectedLocationIndex }"
              :variant="variant"
              :locationFieldValue="locationFieldValue"
              @click="handleLocationSelect(variant, index, null, 'directions')"
            />
          </li>
        </ul>
      </template>
      <!-- hotels -->
      <template v-if="variantsQueryList.hotels.length">
        <div class="location-variants__title">
          {{ t("titleHotels") }}
        </div>
        <ul class="location-variants__list hotels-list">
          <li v-for="(variant, index) in variantsQueryList.hotels" :key="index">
            <LocationItem
              :class="{ 'active-item': index === selectedLocationIndex }"
              :variant="variant"
              :locationFieldValue="locationFieldValue"
              :isHotels="true"
              @click="handleLocationSelect(variant, index, 'hotels')"
            />
          </li>
        </ul>
      </template>
    </template>
    <template v-else>
      <template v-if="variantsHistoryList.length">
        <div class="location-variants__title">
          {{ t("titleHistory") }}
        </div>
        <div class="location-variants__list">
          <HistoryListElement
            v-for="(variant, index) in variantsHistoryList"
            :key="index"
            :variant="variant"
            :index="index"
            :selected-index="selectedHistoryIndex"
            @click="selectedFromHistory(variant, index)"
          >
          </HistoryListElement>
        </div>
      </template>
      <template v-if="variantsPopular">
        <div class="location-variants__title">
          {{ t("titlePopular") }}
        </div>
        <ul class="location-variants__list location-list">
          <li v-for="(variant, index) in variantsPopular" :key="index">
            <LocationItem
              :class="{ 'active-item': index === selectedLocationIndex }"
              :variant="variant"
              @click="handleLocationSelect(variant, index, null, 'popular')"
            />
          </li>
        </ul>
      </template>
    </template>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import variantsPopular from "@/components/base/BaseSuggestions/popular.json";
import HistoryListElement from "@/components/base/BaseSuggestionsUpdated/HistoryListElement.vue";
import LocationItem from "./LocationItem";
import { useI18n } from "vue-i18n";

export default {
  name: "BaseSuggestionsList",
  components:{
    HistoryListElement,
    LocationItem
  },
  props: {
    variantsQuery: {
      type: String,
      default: "",
    },
    locationFieldValue:{
      type: String,
      default: "",
    },
    withoutLast: {
      type: Boolean,
      default: false
    }
  },
  emits: ["applyVariant"],
  mounted() {
    this.loadHistory();
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  methods: {
    ...mapMutations("search", ["setVariantsHistory"]),
    loadHistory() {
      const getStorage = JSON.parse(
        localStorage.getItem("variantsHistory") || "[]"
      );
      if (this.withoutLast) {
        this.variantsHistory = getStorage.slice(0, 4);
      } else {
        this.variantsHistory = getStorage.slice(0, 3);
      }
      this.setVariantsHistory(this.variantsHistory);
    },
    applyVariants(variant, listType = null) {
      this.$emit("applyVariant", variant, listType);
    },
    selectedFromHistory(variant, index) {
      this.selectedHistoryIndex = index;
      const getMatomoConfig = this.matomoConfig['history'];
      matomoHelper(getMatomoConfig());
      this.$emit("applyVariant", variant, "history");
    },
    handleLocationSelect(variant, index, listType = null, matomoKey) {
      this.selectedLocationItem(variant, index, listType);
      const getMatomoConfig = this.matomoConfig[matomoKey || listType];
      if(getMatomoConfig) {
        matomoHelper(getMatomoConfig(variant.title));
      }
    },
    selectedLocationItem(variant, index, listType = null) {
      this.selectedLocationIndex = index;
      setTimeout(() => {
        this.$emit("applyVariant", variant, listType);
      }, 100);
    },
  },
  computed: {
    variantsPopular(){
      return variantsPopular.slice(0, 10 - this.variantsHistory.length);
    },
    variants() {
      if (this.variantsQuery.length === 0) {
        return [...this.variantsHistory, ...this.variantsPopular];
      } else {
        return this.variantsQuery;
      }
    },
    variantsHistoryList() {
      if (!this.withoutLast) {
        return this.variantsHistory;
      } else {
        const variantsHistoryShort = [];
        for (let item = 1; item < this.variantsHistory.length; item++) {
          variantsHistoryShort.push(this.variantsHistory[item]);
        }
        return variantsHistoryShort;
      }
    },
    variantsQueryList(){
      const res = {
        hotels: [],
        directions: []
      };

      this.variantsQuery.forEach((item) => {
        if (item.data?.type === "hotel") {
          res.hotels.push(item);
        } else {
          res.directions.push(item);
        }
      });

      return res.hotels.length || res.directions.length ? res : null;
    }
  },
  data() {
    return {
      isChoice: false,
      variantsHistory: [],
      selectedHistoryIndex: null,
      selectedLocationIndex: null,
      matomoConfig: {
        directions: (title) => {
          return {
            eventCategory: "Search",
            action: 'SearchResultLocation_Select',
            eventName: {
              SearchType: 'Country/Region/City/Street/Object/Address/Metro/Other',
              SerachRequest: title,
            }
          };
        },
        hotels: (title) => {
          return {
            eventCategory: "Search",
            action: 'SearchResultHotels_Select',
            eventName: {
              SearchType: 'Country/Region/City/Street/Object/Address/Metro/Other',
              SerachRequest: title,
            }
          };
        },
        history: () => {
          return {
            eventCategory: "Search",
            action: 'History_Select'
          };
        },
        popular: (title) => {
          return {
            eventCategory: "Search",
            action: 'Popular_Select',
            eventName: title,
          };
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;

  border-radius: 3px;
  box-shadow: inset 0 0 0 2px grey;
  border: 3px solid transparent;
}
.location-variants {
  overflow-y: auto;
  height: calc(100vh - 145px);
  &__list{
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__title {
    padding: 10px 20px 6px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #5A5D63;
  }
}
.active-item {
  background-color: #f1f3fb;
  border-radius: 10px;
}
</style>
