import { default as BookingWLobrcWvbKfUMeta } from "/app/pages/Booking/BookingWL.vue?macro=true";
import { default as indexhv61tE1ElUMeta } from "/app/pages/Booking/index.vue?macro=true";
import { default as LightBookingvTsLOn1OvUMeta } from "/app/pages/Booking/LightBooking.vue?macro=true";
import { default as DetailFotokDJHa0Srf0Meta } from "/app/pages/Detail/DetailFoto.vue?macro=true";
import { default as indexgk4Cv0aJeGMeta } from "/app/pages/Detail/index.vue?macro=true";
import { default as Reviewqx7dcps92DMeta } from "/app/pages/Detail/Review.vue?macro=true";
import { default as ReviewComment3P1FH7vdnPMeta } from "/app/pages/Detail/ReviewComment.vue?macro=true";
import { default as ReviewLocationAhs47Vew4oMeta } from "/app/pages/Detail/ReviewLocation.vue?macro=true";
import { default as HotelN1ViuEuZRaMeta } from "/app/pages/Hotel.vue?macro=true";
import { default as MainmchMlbXHYOMeta } from "/app/pages/Main.vue?macro=true";
import { default as NoticesFramensskhMNFP6Meta } from "/app/pages/NoticesFrame.vue?macro=true";
import { default as PaymentsPjhyFG86liMeta } from "/app/pages/Payments.vue?macro=true";
import { default as PaymentsFrameI1mM0xgOj6Meta } from "/app/pages/PaymentsFrame.vue?macro=true";
import { default as SearchgilRwFjeRXMeta } from "/app/pages/Search.vue?macro=true";
import { default as SpayDqb6prRA6AMeta } from "/app/pages/Spay.vue?macro=true";
export default [
  {
    name: "BookingWL",
    path: "/booking/",
    meta: BookingWLobrcWvbKfUMeta || {},
    component: () => import("/app/pages/Booking/BookingWL.vue")
  },
  {
    name: "Booking",
    path: "/booking/:orderCode",
    meta: indexhv61tE1ElUMeta || {},
    component: () => import("/app/pages/Booking/index.vue")
  },
  {
    name: "LightBooking",
    path: "/booking/light/:orderCode",
    meta: LightBookingvTsLOn1OvUMeta || {},
    component: () => import("/app/pages/Booking/LightBooking.vue")
  },
  {
    name: "DetailFoto",
    path: "/detail/:id/foto",
    meta: DetailFotokDJHa0Srf0Meta || {},
    component: () => import("/app/pages/Detail/DetailFoto.vue")
  },
  {
    name: "Detail",
    path: "/detail/:id",
    meta: indexgk4Cv0aJeGMeta || {},
    component: () => import("/app/pages/Detail/index.vue")
  },
  {
    name: "Review",
    path: "/detail/:objectId/review/:orderId?",
    meta: Reviewqx7dcps92DMeta || {},
    component: () => import("/app/pages/Detail/Review.vue")
  },
  {
    name: "ReviewComment",
    path: "/detail/:objectId/review/:orderId?/step2",
    meta: ReviewComment3P1FH7vdnPMeta || {},
    component: () => import("/app/pages/Detail/ReviewComment.vue")
  },
  {
    name: "ReviewLocation",
    path: "/detail/:objectId/review/:orderId?/step3",
    meta: ReviewLocationAhs47Vew4oMeta || {},
    component: () => import("/app/pages/Detail/ReviewLocation.vue")
  },
  {
    name: "Hotel",
    path: HotelN1ViuEuZRaMeta?.path ?? "/Hotel",
    meta: HotelN1ViuEuZRaMeta || {},
    component: () => import("/app/pages/Hotel.vue")
  },
  {
    name: "Main",
    path: "/",
    meta: MainmchMlbXHYOMeta || {},
    component: () => import("/app/pages/Main.vue")
  },
  {
    name: "NoticesFrame",
    path: "/noticesFrame",
    meta: NoticesFramensskhMNFP6Meta || {},
    component: () => import("/app/pages/NoticesFrame.vue")
  },
  {
    name: "Payments",
    path: "/payments/:paymentsUrl",
    meta: PaymentsPjhyFG86liMeta || {},
    component: () => import("/app/pages/Payments.vue")
  },
  {
    name: "PaymentsFrame",
    path: "/paymentsFrame",
    meta: PaymentsFrameI1mM0xgOj6Meta || {},
    component: () => import("/app/pages/PaymentsFrame.vue")
  },
  {
    name: "Search",
    path: "/search",
    meta: SearchgilRwFjeRXMeta || {},
    component: () => import("/app/pages/Search.vue")
  },
  {
    name: "PagePay",
    path: "/spay/:orderId",
    meta: SpayDqb6prRA6AMeta || {},
    component: () => import("/app/pages/Spay.vue")
  }
]