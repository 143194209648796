

const state = () => ({
  mdlPartner: false,
  showModalRequest: false,
  modalType: "",
});

const getters = {

};

const actions = {
};

// mutations
const mutations = {
  mutMdlPartner(state, payload) {
    state.mdlPartner = payload;
  },
  setShowModalRequest(state, payload) {
    state.showModalRequest = payload;
  },
  setModalType: (state, type) => {
    state.modalType = type;
  }
};

export default {
  namespaced: true,
  namespace: "modal",
  state,
  getters,
  actions,
  mutations,
};
