<i18n>
{
  "ru": {
    "guests": "{n} гостей |{n} гость | {n} гостя | {n} гостей",
    "where": "Куда едем",
    "pets": "с питомцами",
    "map": "Поиск по карте",
    "cities": {
      "spb": "Санкт-Петербург",
      "msk": "Москва",
      "sochi": "Сочи",
      "kzn": "Казань",
      "minsk": "Минск",
      "dg": "Дагестан",
      "abh": "Абхазия",
      "ekb": "Екатеринбург"
    },
    "days": "{n} суток | {n} сутки | {n} суток"
  },
  "en": {
    "guests": "{n} guests | {n} guest | {n} guests | {n} guests",
    "where": "Where we are going",
    "pets": "with pets",
    "map": "Search by map",
    "cities": {
      "spb": "St. Petersburg",
      "msk": "Moscow",
      "sochi": "Sochi",
      "kzn": "Kazan",
      "minsk": "Minsk",
      "dg": "Dagestan",
      "abh": "Abkhazia",
      "ekb": "Ekateriburg"
    },
    "days": "{n} days | {n} day | {n} days"
  }
}
</i18n>

<template>
  <div class="search-panel__wrapper">
    <div class="search-panel" :class="{ 'search-panel--main-page': isMainPage }">
      <div class="search-panel__back" v-if="!isMainPage" @click="back">
        <img src="@/assets/img/arrow-black.svg" alt="" />
      </div>
      <div class="search-panel__main" @click="openSearchWidget">
        <template v-if="isMainPage && !locationTerm">
          <div class="search-panel__options">
            <div class="location">
              {{ t("where") }}<span> — {{ animationText }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="search-panel__options">
            <div class="location">
              {{ getLocationText }}
            </div>
            <div class="dates-guests">
              <span v-if="getDatesText">{{ getDatesText }}, </span
              >{{ getGuestText }}
            </div>
          </div>
        </template>
        <UIButton
          v-if="isMainPage"
          class="search-panel__btn btn-search-main"
          buttonTheme="red"
          @click.stop="clkickSearch"
        ></UIButton>
      </div>
    </div>
    <Teleport to="#teleports">
      <SearchWidget
          v-model="showSearchModal"
          :locationTerm="locationTerm"
          :dates="dates"
          :guests="guests"
          @getObjects="emits('getObjects')"
        />
    </Teleport>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

import SearchWidget from "@/components/mobile/searchWidget";
import UIButton from "@/components/UIKit/UIButton.vue";

const store = useStore();
const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();

const props = defineProps({ searchInMap: Boolean });
const emits = defineEmits(["getObjects"]);

const showSearchModal = computed(() => store.state.search.showSearchModal);
const occupied = computed(() => store.getters["search/occupied"] || "");
const datesQuery = computed(() => {
  if (route.query.occupied || occupied.value) {
    const [checkIn, checkOut] = (route.query.occupied || occupied.value).match(
      /(\d{4}-\d{2}-\d{2})/gm
    );
    if (checkIn !== undefined && checkOut !== undefined) {
      return {
        in: new Date(checkIn),
        out: new Date(checkOut),
      };
    }
    return {
      in: null,
      out: null,
    };
  }
  return {
    in: null,
    out: null,
  };
});
watch(datesQuery, (value) => {
  if (value.in && value.out) {
    dates.value = _.cloneDeep(value);
  }
})
const dates = ref({
  in: null,
  out: null,
});

const guests = computed(() => store.state.search.guests || {});

const openSearchWidget = () => {
  store.commit("search/setShowSearchModal", true);
};
// вывод информации
const locationTerm = computed(() => store.state.search.term || "");
const getLocationText = computed(() => {
  if (props.searchInMap && route.name !== "Main") {
    return t("map");
  }
  return locationTerm.value ? locationTerm.value : t("where");
});
const isOneMonth = computed(() => {
  if (dates.value.in && dates.value.out) {
    return (
      dates.value.in.getFullYear() === dates.value.out.getFullYear() &&
      dates.value.in.getMonth() === dates.value.out.getMonth()
    );
  }
  return true;
});
const isOneYear = computed(() => {
  if (dates.value.in && dates.value.out) {
    return dates.value.in.getFullYear() === dates.value.out.getFullYear();
  }
  return true;
});
const getDatesText = computed(() => {
  const result = {
    in: "",
    out: "",
  };
  if (dates.value.in) {
    result.in = isOneMonth.value
      ? String(dates.value.in.getDate())
      : dates.value.in.toLocaleString(locale || "ru-RU", {
          day: "numeric",
          month: "short",
        });
    if (result.in.slice(-1) === ".") {
      result.in = result.in.slice(0, -1);
    }
    if (!isOneYear.value) {
      result.in += ` ${dates.value.in.toLocaleString(locale || "ru-RU", {
        year: "numeric",
      })}`;
    }
  }
  if (dates.value.out) {
    result.out = dates.value.out.toLocaleString(locale || "ru-RU", {
      day: "numeric",
      month: "short",
    });
    if (result.out.slice(-1) === ".") {
      result.out = result.out.slice(0, -1);
    }
    if (!isOneYear.value) {
      result.out += ` ${dates.value.out.toLocaleString(locale || "ru-RU", {
        year: "numeric",
      })}`;
    }
  }

  if (result.in && result.out) {
    return result.in + " - " + result.out;
  }
  return "";
});
const getGuestText = computed(() => {
  let countGuests = guests.value.adults || 1;
  countGuests += guests.value.childrens?.length || 0;
  if (guests.value.pets?.value) {
    return `${t("guests", countGuests)}, ${t("pets")}`;
  }
  return t("guests", countGuests);
});

// анимация
const cities = ["spb", "msk", "sochi", "kzn", "minsk", "dg", "abh", "ekb"];
const animationText = ref("");
const sitiesIndex = ref(0);
watch(animationText, (value) => {
  const text = cities.at(sitiesIndex.value);
  if (value === t("cities." + text)) {
    setTimeout(() => {
      removeChar();
    }, 2000);
  }
  if (value.length === 0) {
    setTimeout(() => {
      sitiesIndex.value += 1;
      const res = startAnimation();
      if (!res) {
        sitiesIndex.value = 0;
        startAnimation();
      }
    });
  }
});
function startAnimation() {
  const text = cities.at(sitiesIndex.value);
  if (!text) {
    return false;
  }
  addChars(t("cities." + text));
  return true;
}
function addChars(text) {
  let index = 0;
  const interval = setInterval(() => {
    if (index < text.length) {
      animationText.value =
        animationText.value.substring(0, index) +
        text[index] +
        animationText.value.substring(index);
      index++;
    } else {
      clearInterval(interval);
    }
  }, 50);
}
function removeChar() {
  const interval = setInterval(() => {
    if (0 < animationText.value.length) {
      animationText.value = animationText.value.substring(
        0,
        animationText.value.length - 1
      );
    } else {
      clearInterval(interval);
    }
  }, 50);
}

onMounted(() => {
  dates.value = datesQuery.value;
  startAnimation();
  removeIrrelevantVariants();
  loadHistory();
});

function loadHistory() {
  const getStorage = JSON.parse(
    localStorage.getItem("variantsHistory") || "[]"
  );
  if (getStorage.length) {
    let variantsHistory = getStorage.slice(0, 3);
    store.commit("search/setVariantsHistory", variantsHistory);
    return;
  }
  store.commit("search/setVariantsHistory", getStorage);
}

function removeIrrelevantVariants() {
  const variantsHistory = JSON.parse(
    localStorage.getItem("variantsHistory") || "[]"
  );
  const relevantVariants = variantsHistory.filter((variant, index) => {
    const isDatesExist = variant.date?.in && variant.date?.out;
    if (isDatesExist) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(23, 59, 59);

      const dateIn = new Date(variant.date?.in);
      dateIn.setHours(23, 59, 59);

      const isDatesRelevant = yesterday < dateIn;
      if (isDatesRelevant) {
        return variant;
      }
    }
  });
  localStorage.setItem(
    "variantsHistory",
    JSON.stringify(relevantVariants || [])
  );
}

const switchMap = computed(() => store.state.search.switchMap);
function back() {
  if (switchMap.value) {
    store.commit("search/updateSwitchMap", false);
  } else {
    navigateTo({ name: "Main" });
  }
}

// main page
const isMainPage = computed(() => route.name === "Main");
const isValidSearchParams = computed(
  () => locationTerm.value && dates.value.in && dates.value.out
);
const showOnlyOptions = computed(
  () => isMainPage.value && !isValidSearchParams.value
);

function clkickSearch() {
  store.commit("search/setShowSearchModal", true);
}
</script>

<style lang="scss" scoped>
.search-panel__wrapper {
  width: 100%;
}
.search-panel {
  padding: 0 16px 0 20px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(20, 45, 136, 0.1);
  &__back {
    position: relative;
    margin-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    &::after {
      content: "";
      display: block;
      height: 26px;
      width: 1px;
      background: rgb(231, 233, 243);
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    gap: 5px;
    overflow: hidden;
  }

  &__options {
    overflow: hidden;
  }

  .dates-guests {
    color: #5a5d63;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .location {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #1c1c1c;
  }
}

.search-panel.search-panel--main-page {
  padding: 4px 4px 4px 22px;
  box-shadow: 0px 1px 5px 0px rgba(20, 45, 136, 0.02);

  &::after {
    display: none;
  }
  .search-panel__options {
    width: 100%;
  }
  .search-panel__btn {
    width: 42px;
    height: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;

    &::after {
      content: url("~/assets/img/icon/icon_navigation_search.svg");
      transform: translateY(2px);
    }
  }

  .location {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      color: #c0c3d3;
    }
  }
}
</style>
