// initial state

const state = () => ({
  // управление состоянием модалки BookingButton,
  modal: {
    isModalOpen: false,
    step: "calendar",
  },
  // ButtonSwitch управление состоянием кнопки в BookingButton,
  btn: {
    disabled: false,
    loading: false,
  },
  // шаг Guests в модалке  BookingButton,
  guests: {
    isPets: false,
    pets: "",
    petsWarning: false
  },
  // шаг booking в модалке  BookingButton,
  booking: {
    isLoading: false,
    isModalCode: false,
    selectedPhone: null,
    inputPhone: null,
    errors: {
      phone: null,
    },
  },
  // Параметр который смотрим на странице поиска при возвращении назад, если он true есть то показываем модалку
  isChangeForSave: false,
  changesDates: {
    in: null,
    out: null,
  },
  isChangeForSaveGuests: false,
  changesGuests: null,
  canHold: false,
  openDetailGuestsModal: false,
});

// getters
const getters = {
  //возвращает название шага открытой модалки
  modalStep: (state) => {
    return state.modal.step;
  },
  // состояние модалки (открыта/закрыта)
  isModalOpen: (state) => {
    return state.modal.isModalOpen;
  },
  // кнопка в футере
  btnDisabled: (state) => {
    return state.btn.disabled;
  },
  btnLoading: (state) => {
    return state.btn.loading;
  },
  bookingLoading: (state) => {
    return state.booking.isLoading;
  },
  // состояние питомцев в шаге booking
  petsObject: (state) => {
    return {
      isPets: state.guests.isPets,
      pets: state.guests.pets,
      petsWarning: state.guests.petsWarning
    };
  },
  //для редактирования дат и гостей на этапе бронирования
  isBooking: (state, getters, rootState, rootGetters) => {
    return Boolean(rootGetters["detail/getBookingData"]);
  },
  //Проверка на МБ вместе с предупреждениями о запросе
  isInstant: (state, getters, rootState, rootGetters) => {
    return rootGetters["detail/getInstant"] && !state.guests.petsWarning;
  },
  // возращает выбранный телефон в формате цифр, если нет выбранного вернет телефон с инпута
  inputPhoneNumber: (state) => {
    let phone = state.booking.selectedPhone || state.booking.inputPhone;
    if (phone) {
      if (typeof phone === "string")
        return Number(phone.split(" ").join("").slice(1));
      return phone;
    } else {
      return null;
    }
  },
  inputPhoneNumberToString: (state) => {
    // вернет номер телефона в виде строки
    let phone = state.booking.selectedPhone || state.booking.inputPhone;
    if (phone) {
      return phone;
    } else {
      return null;
    }
  },
  isPhoneCorrect: (state, getters) => {
    const PHONE = String(getters.inputPhoneNumber);
    if (PHONE.length >= 11 || (PHONE[0] === "4" && PHONE[1] === "7" && PHONE.length === 10)) {
      return true;
    } else {
      return false;
    }
  },
};

// actions
const actions = {
  // перенаправление в шаге booking (bookingTo)
  async routeBooking(
    { rootGetters, dispatch, commit, state, rootState },
    noMatter = 1
  ) {
    commit("setBtnLoading", true);

    //Если это whitelabel(не важно какой) отправляем ошибку и дальше обработчик перенаправляет на страницу старого черновика
    const whitelabel = rootState?.user?.whitelabel
    if(whitelabel) return new Promise((resolve, reject) => reject())

    const PARAMS = await rootGetters["booking/params"];

    if (!PARAMS && !code) {
      commit("setBtnLoading", false);
      return null;
    }
    const SET_PARAMS = await dispatch(
      "booking/setParams",
      {
        draftCode: PARAMS ? PARAMS.code : code,
      },
      { root: true }
    );
    const ORDER_DRAFT = SET_PARAMS?.data;
    if (ORDER_DRAFT?.draft_type === "mb" || ORDER_DRAFT?.draft_type === "hot") {
      const BOOKING_PRICE = rootGetters["booking/bookingPrice"];
      return await dispatch(
        "booking/bookingToAction",
        {
          order_code: ORDER_DRAFT.code,
          no_matter: noMatter,
          cost: BOOKING_PRICE.price,
          precost: BOOKING_PRICE.precost,
        },
        { root: true }
      ).then((response) => {
        if (
          response?.data?.errors &&
          Object.keys(response.data.errors).length
        ) {
          commit("setBtnLoading", false);
          throw "Action - routeBooking: ошибка из запроса bookingTo ";
        } else if (response?.data?.data?.order_id) {
          dispatch("booking/bookingAnalytics", {}, { root: true });
          goToPayOrder(response.data.data.order_id)
          commit("setBtnLoading", false);
        } else {
          commit("setBtnLoading", false);
        }
        return response;
      });
    } else if (ORDER_DRAFT?.draft_type === "order") {
      commit("setBtnLoading", false);
      //  в мобилке даты в деталке все равно обновляются, так что и геттер актуальный
      let isValidDates = rootGetters["detail/isValidDates"];
      if (await isValidDates) {
        commit("setModalStep", "rules-booking");
        commit("setBookingIsModalCode", false)
        return {};
      } else {
        throw "Action - routeBooking: draft_type = order, невалидные даты";
      }
    } else {
      commit("setBtnLoading", false);
      return {};
    }
  },
  updateSelectedPhone({ state, getters, commit, dispatch }, phone) {
    commit("setSelectedPhone", phone);
    if (getters.isPhoneCorrect) {
      commit("updateBookingError", { name: "phone", value: false });
      if (getters.isBooking) {
        dispatch("booking/updateParams", { phone: phone }, { root: true }).then(
          () => {
            dispatch("booking/updateOrder", { name: "phone" }, { root: true });
          }
        );
      }
    }
  },
  updateInputPhone({ state, getters, commit }, phone) {
    commit("setInputPhone", phone);
    if (getters.isPhoneCorrect)
      commit("updateBookingError", { name: "phone", value: false });
  },
};

// mutations
const mutations = {
  setModal: (state, value) => {
    state.modal = value;
  },
  setIsModalOpen: (state, value) => {
    state.modal.isModalOpen = value;
  },
  setModalStep: (state, value) => {
    state.modal.step = value;
  },
  setIsPets: (state, value = false) => {
    state.guests.isPets = value;
  },
  setPets: (state, value = "") => {
    state.guests.pets = value;
  },
  setOpenDetailGuestsModal: (state, value = false) => {
    state.openDetailGuestsModal = value;
  },
  setPetsWarning: (state, value = false) => {
    state.guests.petsWarning = value;
  },
  setBtnDisabled: (state, value) => {
    state.btn.disabled = value;
  },
  setBtnLoading: (state, value) => {
    state.btn.loading = value;
  },
  setBookingLoading: (state, value) => {
    state.booking.isLoading = value;
  },
  setBookingIsModalCode: (state, value) => {
    state.booking.isModalCode = value;
  },
  updateBookingError: (state, error) => {
    state.booking.errors[error.name] = error.value;
  },
  setSelectedPhone: (state, value) => {
    state.booking.selectedPhone = value;
  },
  setInputPhone: (state, value) => {
    state.booking.inputPhone = value;
  },
  setIsChangeForSave: (state, value) => {
    state.isChangeForSave = value;
  },
  setIsChangeForSaveGuests: (state, value) => {
    state.isChangeForSaveGuests = value;
  },
  setChangesDates: (state, value) => {
    state.changesDates = { ...state.changesDates, ...value };
  },
  setChangesGuests: (state, value) => {
    state.changesGuests = { ...state.changesGuests, ...value };
  },
  setCanHoldDetail: (state, value) => {
    state.canHold = value;
  },
};

export default {
  namespaced: true,
  namespace: "detailBooking",
  state,
  getters,
  actions,
  mutations,
};
