<i18n>
{
  "ru":{
    "save": "Сохранить",
    "next": "Продолжить"
  },
  "en":{
    "save": "Save",
    "next": "Continue"
  }
}
</i18n>

<template>
  <div class="choiseDates">
    <DatesPeriodField v-model:dates="localDates" v-model:activeField="activeField" />
    <div class="choiseDates__main" ref="choiseDatesMain">
      <CalendarForSearchMobile
        v-model:dates="localDates"
        :activeField="activeField"
        @setLastDateElement="lastDateElement = $event"
      />
    </div>
    <transition @after-enter="enter">
      <div class="choiseDates__save" v-show="showSave" ref="saveDatesRef">
        <UIButton buttonTheme="dark" @click="saveDates">
          {{ showOnlyOptions ? t("next") : t("save") }}
        </UIButton>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import DatesPeriodField from "./DatesPeriodField"
import CalendarForSearchMobile from "./DatePicker/CalendarForSearchMobile.vue"
import UIButton from "@/components/UIKit/UIButton.vue"
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  dates: {
    type: Object,
    default: function () {
      return {
        in: null,
        out: null,
      };
    },
  },
  activeField: String,
  showOnlyOptions: Boolean
})
const emits = defineEmits(["update:dates", "finishChoiseDates"])

const activeField = ref("in");
const localDates = ref({
  in: null,
  out: null,
});

const showSave = computed(
  () => !!(localDates.value.in && localDates.value.out)
);
function saveDates(){
  emits("update:dates", localDates.value);
  emits("finishChoiseDates");
  matomoHelper({
    eventCategory: "Search",
    eventAction: 'Calendar_ClickNext',
  });
}
const saveDatesRef = ref()
const choiseDatesMain = ref()
const lastDateElement = ref(null)
function enter(){
  if (saveDatesRef.value && lastDateElement.value) {
    const rect1 = lastDateElement.value.getBoundingClientRect();
    const rect2 = saveDatesRef.value.getBoundingClientRect();

    if (rect1.bottom > rect2.top) {
      const scrollTop =
        rect1.bottom - rect2.top + choiseDatesMain.value.scrollTop;
      choiseDatesMain.value.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }
}

watch(localDates, (value, oldValue) => {
  if (value.in && !value.out) {
    activeField.value = "out"
  }
  if (value.in && oldValue.in && value.in.getTime() < oldValue.in.getTime()) {
    activeField.value = "out"
  }
  if ((!value.in && value.out) || (!value.in && !value.out)) {
    activeField.value = "in"
  }
  if (value.in && value.out) {
    if (
      value.in.getTime() >= value.out.getTime() &&
      activeField.value === "out"
    ) {
      localDates.value.in = value.out;
      localDates.value.out = null;
      activeField.value = "out";
    } else if (
      value.in.getTime() >= value.out.getTime() &&
      activeField.value === "in"
    ) {
      localDates.value.out = null;
      activeField.value = "out";
    }
  }
})

onMounted(() => {
  if (!props.dates.in && !props.activeField) {
    activeField.value = "in";
    localDates.value = props.dates;
    return;
  }

  localDates.value = props.dates;
  activeField.value = props.activeField;
});

watch(
  () => props.dates,
  (val) => {
    localDates.value = val;
  }
);

watch(
  () => props.activeField,
  (val) => {
    activeField.value = val;
})
</script>

<style lang="scss" scoped>
.choiseDates{
  padding: 16px 0;
  height: 100%;
  &__main{
    padding-bottom: 80px;
    margin-top: 30px;
    overflow: auto;
    height: calc(100% - 132px);
  }

  &__save{
    padding: 10px 16px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
