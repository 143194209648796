<i18n lang="json">
{
  "ru":{
    "choose": "Выберите"
  },
  "en":{
    "choose": "Сhoose"
  }
}
</i18n>

<template>
  <div class="ui-select" :class="selectStatus" v-click-outside="closeOptions">
    <template v-if="isHasRemover">
      <div class="ui-select__trigger-wrapper">
        <div class="ui-select__trigger" :class="{ 'ui-select__trigger--error': error }" @click="isOpenSelect = !isOpenSelect">
          <div class="ui-select__placeholder">{{ getPlaceholder }}</div>
          <div class="ui-select__value">{{ getSelectValueText }}</div>
          <span class="icon-app-select ui-select__arrow"></span>
        </div>
        <button class="ui-select__remover" @click="$emit('removeSelect')">
          <span class="icon-app-close-btn"></span>
        </button>
      </div>
    </template>
    <template v-else>
      <div class="ui-select__trigger" @click="isOpenSelect = !isOpenSelect">
        <div class="ui-select__placeholder">{{ getPlaceholder }}</div>
        <div class="ui-select__value">{{ getSelectValueText }}</div>
        <span class="icon-app-select ui-select__arrow"></span>
      </div>
    </template>
    <div class="ui-select__options">
      <div class="ui-select__options-scroll">
        <div
          class="item"
          v-for="(option, index) of options"
          :key="option.value"
          :class="{ 'item-disabled': option.disabled, 'item-selected': option.value === modelValue }"
          @click="changeSelectValue(option, index)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "UISelect",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isOpenSelect: false,
    };
  },
  props: {
    modelValue: {
      required: true,
    },
    options: {
      type: Array,
      required: true,
      default: [
        {
          text: "option1",
          value: 1,
        },
        {
          text: "option2",
          value: 2,
        },
        {
          text: "option3",
          value: 3,
        },
      ],
    },
    placeholder: {
      type: String,
    },
    label: String,
    isHasRemover: {
      type: Boolean,
      defauld: false,
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    selectStatus() {
      let res = ""
      if (this.isOpenSelect) {
        res += "pressed";
      }
      if (this.modelValue !== null && this.modelValue !== undefined) {
        res += " selected";
      } 

      return res;
    },
    getPlaceholder() {
      if (this.label && this.selectStatus.includes("selected")) {
        return this.label
      } 
      if (this.placeholder){
        return this.placeholder;
      }
      return this.t("choose");
    },
    getSelectValueText() {
      if (this.modelValue !== null && this.modelValue !== undefined) {
        const option = this.options.find(
          (item) => item.value === this.modelValue
        );
        return option?.text || this.t("choose");
      }
      return "";
    },
  },
  methods: {
    changeSelectValue(option, index) {
      if (option.disabled === true) {
        return;
      }
      this.$emit("update:modelValue", option.value);
      setTimeout(() => {
        this.isOpenSelect = !this.isOpenSelect;
      }, 100)
    },
    closeOptions() {
      this.isOpenSelect = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-select {
  position: relative;
  width: 100%;
  color: var(--default-fonts-color);

  &__trigger-wrapper {
    display: flex;
    align-items: center;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.05) 3.92%,
        rgba(0, 0, 0, 0) 34.31%
      ),
      #f1f3fb;
    border-radius: 10px;
  }
  &__trigger {
    padding: 0 14px;
    width: 100%;
    height: 42px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e7e9f3;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.2s;
    &--error {
      border: 1px solid #D12D1E;
    }

    &:hover {
      border: 1px solid #c0c3d3;
    }
  }

  &__remover {
    padding: 12px 8px;
    border: none;
    font-size: 0;
    border-radius: 10px;
    background: transparent;
    color: var(--color-main-black, #1c1c1c);
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__placeholder {
    color: var(--grey-g5);
    transition: all ease 0.2s;
    position: absolute;
    top: 11.5px;
  }
  &__trigger--error .ui-select__placeholder{
    color: #D12D1E;
  }

  &__arrow {
    font-size: 18px;
    font-weight: 700;
    transition: all ease 0.2s;
  }

  &__options {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
    padding: 4px;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
      0px 4px 15px rgba(20, 45, 136, 0.06);
    border-radius: 14px;
    z-index: 2;
    transition: all ease 0.2s;

    .item {
      padding: 0 12px;
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      font-weight: 500;
      transition: background ease 0.2s;
      cursor: pointer;
      &-disabled {
        color: #5A5D63;
        pointer-events: none;
      }
      &-selected {
        background-color: #F1F3FB;
        border-radius: 10px;
      }
    }
    .item:hover {
      background: #f1f3fb;
    }
  }
  &__value {
    visibility: hidden;
  }
}

.ui-select.pressed {
  .ui-select__trigger {
    border: 1px solid #c0c3d3;
  }
  .ui-select__arrow {
    transform: rotateX(180deg);
  }
  .ui-select__options {
    max-height: 170px;
    height: 1000px;
    visibility: visible;
    z-index: 100;
  }
  .ui-select__options-scroll {
    overflow: auto;
    max-height: 100%;
  }
}
.ui-select.selected {
  .ui-select__placeholder {
    padding: 0 4px;
    position: absolute;
    left: 10px;
    top: -9px;
    background: #fff;
    font-size: 12px;
    line-height: 16px;
  }
  .ui-select__value {
    visibility: visible;
  }
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e7e9f3;

  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #e7e9f3;
  border: 3px solid transparent;

  &:hover {
    box-shadow: inset 0 0 0 2px #999999;
  }
}
</style>
