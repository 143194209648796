import isPenultimateDigitEven from "~/utils/isPenultimateDigitEven";

export default (orderId) => {
  try {
    if(document && typeof document !== "undefined") {
      if(!orderId) {
        console.error("no orderId in goToPayOrder", orderId);
        return
      }
      const targetPage = isPenultimateDigitEven(orderId) ? "spay" : "pay";
      const origin = document.location.origin;
      document.location.href = `${origin}/${targetPage}/${orderId}`
    }
  } catch (e) {
    console.error(e, "error in goToPayOrder");
  }
}
